import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import {Loading} from '@elements';

import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '../../api/openapi-axios-client';

import {convertValue} from '../../helpers/results';
import {truncateString} from '../../helpers/strings';

const ReportPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const {tokenContext, userContext} = useContext(RootContext);
    const {token} = tokenContext;    
    const [resultData, setResultData] = useState([]);
    const [visibleLimit, setVisibleLimit] = useState(3);
    const {currentUser} = userContext;
    
    const navigate = useNavigate();

    useEffect(() => {

        const getData = async () => {
            try {
                const data = await assessmentApi.ratings.getUserHistoryRating(currentUser.id, true);

                if(data)  {
                    setResultData(data);
                }
    
                setIsLoading(false);
            }
            catch {
                setIsLoading(false);
            }
        };

        if(token && !resultData.length) {
            getData();
        }
    }, [token]);


    const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

    const BuildClusterObject = (data) => {

        const gaugeValue = convertValue(data.averageRating)
        let colorToUse = '#FF1414';

        if(gaugeValue <=45) {
            colorToUse = '#FF1414';
        }
        else if(gaugeValue > 45 && gaugeValue <= 79) {
            colorToUse = '#FFC000';
        }
        else if(gaugeValue > 79) {
            colorToUse = '#33F600';
        }

        return (
            <Box key={`result-${data.category}`} sx={{display:'flex', flexDirection:'column', alignItems:'center', boxShadow: 3, padding: 2, cursor: 'pointer', background: 'white', maxWidth: 225, minWidth: 225}} onClick={() => navigate(`/careers/details/${currentUser.id}/${data.categoryId}`, {state: {clusterData: data}})}>
                <Box sx={{display:'flex', color:colorToUse, justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress variant="determinate" value={gaugeValue} color='inherit' size={120} thickness={6} sx={{borderRadius: '50%', boxShadow: `inset 0 0 0 ${6/44*120}px ${setOpacity(colorToUse, .2)}`}}/>
                    <Typography position='absolute' color="black" textAlign="center" fontSize='10pt'>{`${gaugeValue}%`}<br />Match</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', marginTop:2}}>
                    <Typography sx={{fontWeight: 900, fontSize: '10pt', minHeight: 45, maxHeight: 45}}>{data.category}</Typography>
                    <Typography sx={{height: '100%', fontSize: '10pt'}}>
                        {truncateString(data.categoryDescription, 75, false)}<br />
                    </Typography>
                    <Typography sx={{color: '#0709FF', fontWeight: 'bold', fontSize: '10pt', marginTop: 1}}>
                        ... Read more
                    </Typography>
                </Box>
            </Box>
        );
    }

    const buildOccupationItem = (cluster, occupation) => {
        if(occupation.averageRating < 5) {
            return null
        }

        occupation.details = occupation.onet;

        return (
            <Box key={occupation.code} sx={{cursor:'pointer'}} onClick={() => navigate(`/careers/details/${currentUser.id}/${cluster.categoryId}/career/${cluster.categoryId}-${occupation.occupationId}-${occupation.occupationId}`, {state: {careerData: {category: 'Results', occupation}}})}>
                <Grid container columnSpacing={2} sx={{alignItems: 'center', marginTop: '5px', marginBottom: '5px'}}>
                    <Grid size={5}>
                        <Typography sx={{fontSize: 14}}>
                            {occupation.title}
                        </Typography>
                    </Grid>
                    <Grid size={4}>
                        <Typography sx={{fontSize: 14}}>
                            {cluster.category}
                        </Typography>
                    </Grid>
                    <Grid size={3}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'end', alignItems: 'center'}}>
                            {occupation?.onet?.tags?.bright_outlook && (
                                <Typography sx={{fontSize: 14, color: 'red'}}>
                                    {`${String.fromCodePoint('0x1F680')} Fast-growing career`}
                                </Typography>) }
                            <KeyboardArrowRight sx={{color: 'grey.500', fontSize: '2.0rem', alignSelf: 'end'}}/>
                        </div>
                    </Grid>
                </Grid>
                <Divider/>
            </Box>
        );
    }

    const EmptyData = () => {
        return (
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height: '100vh'}}>
                <Typography variant="h6">No data available</Typography>
            </Box>
        )
    }

    const ResultData = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', padding: '16px'}}>
                <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
                    Top 3 Industry Matches
                </Typography>
                <div style={{display: 'flex', flexDirection: 'row', gap: 30, marginTop: 10}}>
                    {resultData.slice(0, visibleLimit).map((data) => BuildClusterObject(data))}
                </div>
                <Divider sx={{marginTop: 3, marginBottom: 3}}/>
                <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
                    Recommended Career Paths Based on Videos Watched
                </Typography>
                <Typography sx={{fontSize: 14, marginTop: 1}}>
                    These are the career paths we'd recommend based on the videos you've watched. Watch more videos to uncover more career paths.
                </Typography>
                <Box padding={2} sx={{marginTop:1, borderRadius: 2, border: 1, borderColor: 'grey.500', bgcolor: 'white'}}>
                    <Grid container spacing={2}>
                        <Grid size={5}>
                            <Typography sx={{fontSize: 16, color: 'grey.500'}}>
                                Occupations
                            </Typography>
                        </Grid>
                        <Grid size={4}>
                            <Typography sx={{fontSize: 16, color: 'grey.500'}}>
                                Industry
                            </Typography>
                        </Grid>
                        <Grid size={3}>
                            <Typography sx={{fontSize: 16, color: 'grey.500'}}>
                                Occupaction Outlook
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop: 1}}/>
                    {resultData.map((cluster) => cluster.occupationData.map((occupation) => buildOccupationItem(cluster, occupation)))}
                </Box>
            </div>
        )
    }

    return (
        <div>
            {
                (isLoading && <Loading />) || (resultData.length === 0 && <EmptyData />) || <ResultData />
            }
        </div>
    )
}


export default ReportPage