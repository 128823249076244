import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BackArrow from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {convertValue} from '../../helpers/results';

import RootContext from '../../services/context-states/root-context';
import {assessmentApi} from '@apis';
import {HorizontalScrollBox, Loading} from '@elements';
import {truncateString} from '../../helpers/strings';

const ClusterDetailsPage = () => {
    const {messageContext} = useContext(RootContext);
    const {setError} = messageContext;

    const [loading, setLoading] = useState(true);
    const [occupationDetails, setOccupationDetails] = useState([]);
    const [clusterDisplayData, setClusterDisplayData] = useState({});

    const navigate = useNavigate();

    const {state} = useLocation();
    const {clusterData} = state || {};
    const {userId, careerClusterId} = useParams()

    useEffect(() => {
        
        const getDetail = async () => {
            // If the clusterData is not passed, will need to grab it from the API
            let dataForDisplay = {};

            if(!clusterData) {
                const clusters = await assessmentApi.ratings.getUserHistoryRating(userId);

                if(!clusters) {
                    setError('There was an error retrieving the cluster data.');
                    setLoading(false);
                    return;
                }

                const matchedCluster = clusters.find(cluster => cluster.categoryId === careerClusterId);

                if(!matchedCluster) {
                    setError('There was an error retrieving the cluster data.');
                    setLoading(false);
                    return;
                }

                dataForDisplay = {
                    category: matchedCluster.category,
                    averageRating: matchedCluster.averageRating,
                    categoryDescription: matchedCluster.categoryDescription,
                    occupationData: matchedCluster.occupationData
                }

                setClusterDisplayData(dataForDisplay);
            }
            else {

                dataForDisplay = {
                    category: clusterData.category,
                    averageRating: clusterData.averageRating,
                    categoryDescription: clusterData.categoryDescription,
                    occupationData: clusterData.occupationData
                }

                setClusterDisplayData(dataForDisplay);
            }

            if(dataForDisplay && dataForDisplay.occupationData) {
                const occupationData = [];

                try{
                    for(const occupation of dataForDisplay.occupationData) {

                        const response = await assessmentApi.careerData.getONetCareerData(occupation.code);
    
                        if(response && response.code) {
                            occupationData.push({...occupation, details: response});
                        }
                    }
  
                    setOccupationDetails(occupationData.sort((a, b) => b.averageRating - a.averageRating));
                }
                catch(err) {
                    setError('Unable to load career details');
                    setOccupationDetails([]);
                }
            }

            setLoading(false);
        };

        getDetail();
    }, [])

    const generateCareerCard = (category, occupation, i) => {
        return (
            <Box key={i} sx={{borderRadius: 1, border: .5, borderColor: 'grey.500', padding:1, bgcolor: 'white', width: 300, height: 200, margin: 1, boxShadow: 4, cursor: 'pointer'}} onClick={() => navigate(`/careers/details/${userId}/${careerClusterId}/career/${careerClusterId}-${occupation.occupationId}-${occupation.occupationId}`, {state: {careerData: {category, occupation}}})}>
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', gap: 10, justifyContent: 'center'}}>
                    <Typography sx={{fontSize: 16, fontWeight: 900, color: 'black'}}>
                        {truncateString(occupation.title, 50)}
                    </Typography>
                    {occupation?.details?.tags?.bright_outlook && (
                        <Typography sx={{fontSize: 14, color: 'red'}}>
                            {`${String.fromCodePoint('0x1F680')} Fast-growing career`}
                        </Typography>)
                    }
                    <Typography sx={{fontSize: 14, color: '#0709FF'}}>
                        {`${convertValue(occupation?.averageRating)}% match`}
                    </Typography>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Typography sx={{fontSize: 14, color: 'grey.500'}}>
                            See more
                        </Typography>
                        <ArrowRightAltIcon sx={{color: 'grey.500'}}/>
                    </div>
                </div>
            </Box>
        )
    }

    return (
        <>
        {(loading && <Loading />) || 
            <Grid container spacing={2} sx={{width:'100%', height:'100%'}}>
                <Grid size={ 12 }>
                    <Stack direction='row' spacing={0} display={'flex'} alignItems={'center'}>
                        <IconButton onClick={() => navigate('/report')}>
                            <BackArrow sx={{color: 'grey.500', fontSize: '2.5rem'}}/>
                        </IconButton>
                        <Typography sx={{fontSize: 20, color: 'grey.500', verticalAlign:'center', cursor:'pointer'}} onClick={() => navigate('/report')}>
                            Return to Top Interests
                        </Typography>
                    </Stack>
                </Grid>
                <Grid container size={ 12 } sx={{marginLeft:4}}>
                    <Grid size={ 12 }>  
                        <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
                            {clusterDisplayData?.category}
                        </Typography>
                    </Grid>
                    <Grid size={ 12 }>
                        <Typography sx={{fontSize: 16, color: 'black'}}>
                            {`You had a ${convertValue(clusterDisplayData?.averageRating)}% match with this cluster.`}
                        </Typography>
                    </Grid>
                    <Grid size={ 12 } sx={{marginTop: 2}}>
                        <Box padding={4} sx={{borderRadius: 2, border: 1, borderColor: 'grey.500', bgcolor: 'white'}}>
                            <Grid container spacing={2}>
                                <Grid size={ 12 }>
                                    <Typography sx={ {fontWeight: 900, fontSize: 22, color: 'grey.500'} }>
                                        Overview
                                    </Typography>
                                </Grid>
                                <Grid size={ 12 }>
                                    <Typography sx={{fontSize: 16, color: 'black'}}>
                                        {clusterDisplayData?.categoryDescription}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container size={ 12 } sx={{marginTop: 4}}>
                        <Grid size={ 12 }>
                            <Divider />
                        </Grid>
                        <Grid size={ 12 } sx={{marginTop:4}}>
                            <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
                                Potential Career Paths
                            </Typography>
                        </Grid>
                        <Grid size={ 12 }>
                            <Typography sx={{fontSize: 14, color: 'black'}}>
                                Career paths that can be found in these top categories.
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <HorizontalScrollBox toRender={occupationDetails.map((occupation, i) => generateCareerCard(clusterDisplayData?.category, occupation, i))} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
        </>
    )
}

export default ClusterDetailsPage