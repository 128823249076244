import React from 'react'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import MenuBook from '@mui/icons-material/MenuBook';
import HistoryIcon from '@mui/icons-material/History';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import * as permissionHelper from '../../../helpers/permissions';
import {enableVideo} from '../../../helpers/entitlements';

const enableAdmin = (userData) => {

    if(!userData || !userData.additionalData || !userData.additionalData.role || !userData.additionalData.role.permissions) {
        return false;
    }

    if((!permissionHelper.checkPermission(userData?.additionalData.role.permissions || [], permissionHelper.permissions.ManageBilling) 
        && !permissionHelper.checkPermission(userData.additionalData.role.permissions || [], permissionHelper.permissions.ManageUsers))) {
        return false;
    }

    return true;
}

const hideResultsLibrary = (billingInformation, userData) => {

    if(!billingInformation || !userData) {
        return true;
    }

    if(enableAdmin(userData)) {
        return false;
    }

    if(enableVideo(userData, billingInformation)) {
        return false;
    }

    return true;
}

export const mainDrawer = (userData, billingInformation, accessFlags) => {

    if(!userData || Object.keys(userData).length === 0 || !userData.additionalData) {
        return [];
    }

    return [
        {
            disabled: false,
            hidden: false,
            icon: <HomeOutlinedIcon />,
            showBadge: false,
            badgeValue: -1,
            key: 'home',
            route: '/home',
            text: 'Home',
            subroutes: [],
            displayDivider: false
        },
        {
            disabled: false,
            hidden: true,
            icon: <NotificationsOutlinedIcon />,
            showBadge: false,
            badgeValue: -1,
            key: 'notifications',
            route: '/notifications',
            text: 'Notifications',
            subroutes: [],
            displayDivider: true
        },
        {
            disabled: !enableVideo(userData, billingInformation),
            hidden: false,
            icon: <PlayArrowOutlinedIcon />,
            showBadge: false,
            badgeValue: -1,
            key: 'videos',
            text: 'Videos',
            subroutes: [{
                disabled: false,
                hidden: false,
                icon: <PlayArrowOutlinedIcon />,
                showBadge: false,
                badgeValue: -1,
                key: 'videosWatch',
                route: '/videos/watch',
                text: 'Watch',
            }, {
                disabled: true,
                hidden: true,
                icon: <ThumbUpOffAltIcon />,
                showBadge: false,
                badgeValue: -1,
                key: 'videosLiked',
                route: '/videos/liked',
                text: 'Liked Videos',
            }, {
                disabled: false,
                hidden: false,
                icon: <HistoryIcon />,
                showBadge: false,
                badgeValue: -1,
                key: 'videosHistory',
                route: '/videos/history',
                text: 'History',
            },],
            displayDivider: true
        },
        {
            disabled: false,
            hidden: hideResultsLibrary(billingInformation, userData),
            icon: <BarChartOutlinedIcon />,
            showBadge: false,
            badgeValue: -1,
            key: 'results',
            text: 'Results',
            subroutes: [
                {
                    disabled: false,
                    hidden: !enableVideo(userData, billingInformation),
                    showBadge: false,
                    badgeValue: -1,
                    key: 'report',
                    route: '/report',
                    text: 'Report',
                    subroutes: [],
                    displayDivider: false
                },
                {
                    disabled: false,
                    hidden: !enableAdmin(userData),
                    showBadge: false,
                    badgeValue: -1,
                    key: 'share',
                    route: '/share',
                    text: 'Share',
                    subroutes: [],
                    displayDivider: false
                }
            ],
            displayDivider: true
        },
        {
            disabled: false,
            hidden: hideResultsLibrary(billingInformation, userData),
            icon: <MenuBook />,
            showBadge: false,
            badgeValue: -1,
            key: 'library',
            route: '/library',
            text: 'Library',
            subroutes: [],
            displayDivider: false
        },
        {
            disabled: false,
            hidden: false,
            icon: <SettingsOutlinedIcon />,
            showBadge: (accessFlags.showIncompleteProfile || accessFlags.showUnusedLicenses) && (accessFlags.enableManageBilling || accessFlags.enableManageUsers),
            badgeValue: -1,
            key: 'settings',
            route: '/settings',
            text: 'Settings',
            subroutes: [],
            displayDivider: false
        }
    ]
}