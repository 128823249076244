import React, {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackArrow from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAlt from '@mui/icons-material/FilterAlt';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';

import {assessmentApi} from '@apis';
import {Loading} from '@elements';

import {titleCase} from 'title-case';
import {returnSalaryData} from '../../helpers/results';

import RootContext from '../../services/context-states/root-context';

const LibraryClusterDetailsPage = () => {
  const {messageContext, userContext} = useContext(RootContext);
  const {setError} = messageContext;
  const {currentUser} = userContext;

  const [isLoading, setIsLoading] = useState(true);
  const [displayCluster, setDisplayCluster] = useState({});
  const [clusterDetails, setClusterDetails] = useState([]);
  const [filters, setFilters] = useState({search: '', income: '', degree: '', fastGrowing: false});
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('az');
  const [page, setPage] = React.useState(0); 
  const [recordsPerPage, setRecordsPerPage] = React.useState(25); 

  const navigate = useNavigate();
  const {clusterId} = useParams();

  useEffect(() => {
    
    const getDetails = async () => {

      try {
        const cluster = await assessmentApi.careerData.getCareerCluster(clusterId);
        const response = await assessmentApi.careerData.getONetOccupationsDetailsForCluster(clusterId, 'all');
  
        response.forEach(occupation => {
          occupation.convertedSalary = returnSalaryData(occupation?.salary);
        });
  
        const filteredResponse = response.filter((occupation) => occupation.occupationAlternateTitle !== '');
  
        setDisplayCluster(cluster);
        setClusterDetails(filteredResponse);
        setIsLoading(false);
  
      }
      catch {
        setError('Error retrieving career cluster details. Please try again later.');
      }
    }

    if(displayCluster && clusterDetails.length) {
      return;
    }
    
    getDetails();

  }, [])

  const degressFilterOptions = [
    'no high school diploma/GED',
    'high school diploma/GED',
    'certificate after high school',
    'some college',
    'associate\'s degree',
    'bachelor\'s degree',
    'certificate after college',
    'master\'s degree',
    'certificate after master\'s',
    'professional degree',
    'doctoral degree',
    'post-doctoral training'
  ]

  const getFilteredData = () => {

    if(!filters.search && !filters.income && !filters.degree && !filters.fastGrowing) {
      return handleOrdering(clusterDetails);
    }

    let filteredData = clusterDetails;

    if(filters.search) {
      filteredData = filteredData.filter(occupation => occupation.occupationAlternateTitle.toLowerCase().includes(filters.search.toLowerCase()));
    }

    if(filters.income) {
      const [min, max] = filters.income.split(':');
      filteredData = filteredData.filter(occupation => occupation.convertedSalary[1].annual >= parseInt(min) && occupation.convertedSalary[1].annual <= parseInt(max));
    }

    if(filters.degree) {
      filteredData = filteredData.filter(occupation => occupation?.education?.level_required?.category?.some((category) => category.name.toLowerCase() === filters.degree.toLowerCase()));
    }

    if(filters.fastGrowing) {
      filteredData = filteredData.filter(occupation => occupation.fastGrowing);
    }

    return handleOrdering(filteredData);
  }

  const handleSortChange = (event) => {
    setOrdering(event.target.value);
  };

  const handleOrdering = (dataToOrder) => {
    if(ordering === 'az') {
      return dataToOrder.sort((a, b) => a.occupationAlternateTitle.localeCompare(b.occupationAlternateTitle));
    }
    else {
      return dataToOrder.sort((a, b) => b.occupationAlternateTitle.localeCompare(a.occupationAlternateTitle));
    }
  }

  const handleFilterChange = (key, value) => {
    setFilters({...filters, [key]: value});
  }

  const handleChangePage = (event, newpage) => { 
    setPage(newpage); 
  } 

  const handleChangeRowsPerPage = (event) => { 
    setRecordsPerPage(parseInt(event.target.value, 10)); 
    setPage(0); 
  } 

  const buildOccupationItem = (occupation) => {
    return (
      <Box key={occupation.code} sx={{cursor:'pointer'}} onClick={() => navigate(`/careers/details/${currentUser.id}/${clusterId}/career/${clusterId}-${occupation.occupationId}-${occupation.occupationAlternateTitleId}`)}>
        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:1}}>
          <Box sx={{flex:1, marginRight:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
            <Typography sx={{flexGrow:1}}>
              {occupation.occupationAlternateTitle}
            </Typography>
            {occupation.fastGrowing && (
              <Typography sx={{fontSize: 14, color: 'red'}}>
                  {`${String.fromCodePoint('0x1F680')} Fast-growing career`}
              </Typography>) }
          </Box>
          <KeyboardArrowRight sx={{color: 'grey.500', fontSize: '2.0rem'}}/>
        </Box>
        <Divider sx={{marginTop:1}}/>
      </Box>
    )
  }

  const getOccupationRows = () => {
  
    const filteredData = getFilteredData();
    
    // Reset the Page if the number of records filtered is less than the current page
    if(page > Math.floor(filteredData.length / recordsPerPage)) {
      setPage(0);
    }

    return (
      <div>
        {filteredData.slice(page * recordsPerPage, page * recordsPerPage + recordsPerPage).map((occupation) => buildOccupationItem(occupation))}
        <TablePagination rowsPerPageOptions={[25, 50, 100]} component='div' count={filteredData.length} rowsPerPage={recordsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} /> 
      </div>
    )
  }

  return (
    <Box>
      {(isLoading && <Loading />) ||
        <>
        <Stack direction='row' spacing={0} display={'flex'} alignItems={'center'} sx={{marginBottom:2}}>
          <IconButton onClick={() => navigate('/library')}>
              <BackArrow sx={{color: 'grey.500', fontSize: '2.5rem'}}/>
          </IconButton>
          <Typography sx={{fontSize: 20, color: 'grey.500', verticalAlign:'center', cursor:'pointer'}} onClick={() => navigate('/library')}>
              Return to Career Clusters
          </Typography>
        </Stack>
        <Box sx={{marginLeft:4, display:'flex', flexDirection:'column', gap:'10px'}}>
          <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
              {displayCluster?.title}
          </Typography>
          <Box padding={4} sx={{borderRadius: 2, border: 1, borderColor: 'grey.500', bgcolor: 'white', display:'flex', flexDirection:'column', rowGap:2}}>
            <Typography sx={ {fontWeight: 900, fontSize: 22, color: 'grey.500'}}>
              Overview
            </Typography>
            <Typography sx={{fontSize: 16, color: 'black'}}>
                {displayCluster?.description}
            </Typography>
          </Box>
          <Divider sx={{marginTop:4, marginBottom:3}}/>
          <Box>
            <Typography sx={{fontSize: 22, fontWeight: 900, color: 'black'}}>
                Potential Career Paths
            </Typography>
            <Typography sx={{fontSize: 16, color: 'black'}}>
                Career Paths that can be found in {displayCluster?.title}
            </Typography>
            <TextField variant='outlined' 
              placeholder={'Search (Press Enter to Search)'} 
              value={search} 
              sx={{fontSize: 14, marginTop:1, marginBottom:1, width:650, background:'white'}} size={'small'} 
              onChange={(e) => setSearch(e.target.value)} 
              onKeyDown={(e) => {if (e.key === 'Enter') { handleFilterChange('search', e.target.value)}}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment:(
                  <InputAdornment position="end">
                    {filters.search &&
                      <IconButton onClick={() => {setSearch(''); handleFilterChange('search', '')}}>
                        <CancelIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )}} />
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', flexGrow:1}}>
                <FilterAlt sx={{color: 'black', fontSize: '1.5rem'}}/>
                <Typography sx={{fontSize: 16, color: 'black', fontWeight:900, marginLeft:2}}>
                  Filters:
                </Typography>
                <Select sx={{marginLeft:4, fontSize:14, width:250, fontWeight:900}} variant='standard' value={filters.income} placeholder='Average Projected Income' displayEmpty onChange={(e) => handleFilterChange('income', e.target.value)} size={'small'}>
                  <MenuItem value={''}>{'Average Projected Income (All)'}</MenuItem>
                  <MenuItem value={'1:19999'}>{'<$20,000'}</MenuItem>
                  <MenuItem value={'20000:39999'}>{'$20,000-$39,999'}</MenuItem>
                  <MenuItem value={'40000:59999'}>{'$40,000-$59,999'}</MenuItem>
                  <MenuItem value={'60000:79999'}>{'$60,000-$79,999'}</MenuItem>
                  <MenuItem value={'80000:99999'}>{'$80,000-$99,999'}</MenuItem>
                  <MenuItem value={'100000:119999'}>{'$100,000-$119,999'}</MenuItem>
                  <MenuItem value={'120000:139999'}>{'$120,000-$139,999'}</MenuItem>
                  <MenuItem value={'140000:159999'}>{'$140,000-$159,999'}</MenuItem>
                  <MenuItem value={'160000:179999'}>{'$160,000-$179,999'}</MenuItem>
                  <MenuItem value={'180000:200000'}>{'$180,000-$200,000'}</MenuItem>
                  <MenuItem value={'99999999'}>{'>$200,000'}</MenuItem>
                </Select>
                <Select sx={{marginLeft:4, fontSize:14, width:250, fontWeight:900}} variant='standard' value={filters.degree} placeholder='Degree Required' displayEmpty onChange={(e) => handleFilterChange('degree', e.target.value)} size={'small'}>
                  <MenuItem value={''}>{'Degree Required (All)'}</MenuItem>
                  {degressFilterOptions.map((degree, index) => (<MenuItem key={`degree-filter-${index}`} value={degree}>{titleCase(degree)}</MenuItem>))}
                </Select>
                <Select sx={{marginLeft:4, fontSize:14, width:250, fontWeight:900}} variant='standard' value={filters.fastGrowing} placeholder='Occupation Outlook' displayEmpty onChange={(e) => handleFilterChange('fastGrowing', e.target.value)} size={'small'}>
                  <MenuItem value={false}>{'Occupation Outlook (All)'}</MenuItem>
                  <MenuItem value={true}>{'Fast-growing career'}</MenuItem>
                </Select>
              </Box>
              <Select value={ordering} sx={{fontSize:14, background:'white'}} onChange={handleSortChange} size={'small'}>
                <MenuItem value={'az'}>{'Sort by: A -> Z'}</MenuItem>
                <MenuItem value={'za'}>{'Sort by: Z -> A'}</MenuItem>
              </Select>
            </Box>
            <Box padding={2} sx={{marginTop:1, borderRadius: 2, border: 1, borderColor: 'grey.500', bgcolor: 'white'}}>
              <Typography sx={{fontSize: 16, color: 'grey.500'}}>
                Occupations
              </Typography>
              <Divider/>
              {clusterDetails.length > 0 && 
                getOccupationRows()
              }
            </Box>
          </Box>
        </Box>
        </>
      }
    </Box>
  )
}

export default LibraryClusterDetailsPage